import * as React from "react";
import Layout from "../components/global/layout/layout";
import {graphql} from "gatsby";
import SmallHeader from "../components/global/small-header/small-header";

const THERAPIES = [
  'Angststörungen',
  'Anpassungsstörungen',
  'Aufmerksamkeitsstörungen (ADS, ADHS)',
  'Bindungsstörungen',
  'Depressionen',
  'Panikstörungen',
  'Persönlichkeitsentwicklungsstörungen',
  'Phobien',
  'Schlafstörungen, Alpträume',
  'Schulangst und Schulvermeidung',
  'selbstverletzendes Verhalten',
  'Somatisierungsstörungen ',
  'soziale Ängste / Unsicherheiten',
  'Störungen des Sozialverhaltens',
  'Ticstörungen',
  'Trennungs- und Scheidungsfolgen',
  'Traumatisierungen',
  'Zwangsstörungen'
];

export const pageQuery = graphql`
  query SpectrumQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
  }
`;

export default props => {
  return (
    <Layout title={props.data.site.siteMetadata.title}
            description={props.data.site.siteMetadata.description}
            titleSuffix="Behandlungsspektrum"
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <SmallHeader title={'Behandlungsspektrum'}
                   backgroundImage={'/images/behandlungsspektrum/weber-nilges-behandlungsspektrum.jpg'}/>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12 col-lg-9">
            <h2>
              Ich behandle Menschen, die an folgenden Krankheitsbildern leiden:
            </h2>
          </div>
        </div>
        <div className="row mt-4 mb-2">
          {
            THERAPIES.map((therapy, index) => (
              <div key={index} className="col-12 col-md-6 col-lg-4 my-3 my-md-4">
                <span>{therapy}</span>
              </div>
            ))
          }
        </div>
      </div>
    </Layout>
  );
}
